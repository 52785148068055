var interval;
export default {
    data() {
        return {
            checkSession: ''
        }
    },
    methods: {
        getParameterByName(name) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(window.location.href);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getParameterByNameURL(name, url) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getCheckCookie() {
            interval = setInterval(() => {
                const vbgcCookie = getCookie(iamvar_vbgcCookieName)
                this.checkSession = vbgcCookie ? true : false
                if (!vbgcCookie) {
                    clearInterval(interval)
                }
            }, 1000)
        },
        destroyInterval() {
            clearInterval(interval)
        },
        csidBiocatch(csid) {
            // eslint-disable-next-line no-undef
            // cdApi.setCustomerSessionId(getCookie('vbgBioCustomerSesssionId'))
            //cdApi.setCustomerSessionId(csid)
        },
        changeContextBiocatch(contextName) {
            if (iamvar_bioCatchToggle) {
                try {
                    // eslint-disable-next-line no-undef
                    setTimeout(() => {
                        window.cdApi.changeContext(contextName)
                    }, 2000)
                }
                catch {
                    console.log("biocatch scripts not working")
                }
            }
        },

        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            } else {
                return null;
            }
        },
        getDomain(url) {
            var hostName = this.getHostName(url);
            var domain = hostName;

            if (hostName != null) {
                var parts = hostName.split('.').reverse();

                if (parts != null && parts.length > 1) {
                    domain = parts[1] + '.' + parts[0];

                    // if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                    //   domain = parts[2] + '.' + domain;
                    // }
                }
            }

            return domain;
        },

        /*
        Make function Includes (capital I to differentiate from includes which exists in JavaScript)
        to make all includes calls compatible with Internet Explorer
         */
        Includes(container, targetValue) {
            var containsValue = false;

            if (container.indexOf(targetValue) >= 0)
                containsValue = true;

            return containsValue;
        },
        getAllowedCharacters(url) {
            let alphanum = /^[ A-Za-z0-9]+$/;
            let specialChar = ['$', '&', '+', ',', '/', ':', ';', '=', '?', '@', '-', '.', '_', '~', '#', '%', '!'];
            let invalidChar = false;
            for (let i = 0; i < url.length; i++) {
                if (!((url.charAt(i).match(alphanum)) || this.Includes(specialChar, url.charAt(i)))) {
                    invalidChar = true;
                    break;
                }
            }
            if (!invalidChar) {
                return url;
            } else {
                return null;
            }

        },
        sanitizeUrl(url) {
            var approvedUrls = iamvar_vzApprovedUrls;

            if (!url) {
                return null;
            }
            let approvedUrlFound = false;
            // allowing only alphanumeric and some special characters in a url
            let sanitizedUrl = this.getAllowedCharacters(url);
            if (sanitizedUrl) {
                // if the coming url begins with /, it means it is a redirection within the same domain
                if (sanitizedUrl.charAt(0) == "/") {
                    approvedUrlFound = true;
                } else {
                    let mainDomain = this.getDomain(sanitizedUrl);
                    for (let i = 0; i < approvedUrls.length; i++) {
                        if (mainDomain == approvedUrls[i]) {
                            approvedUrlFound = true;
                            break;
                        }
                    }
                }
                if (approvedUrlFound) {
                    return sanitizedUrl;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        //sanitize string to avoid xss 
        sanitizeString(payload) {
            return String(payload).replace(/[|&;$%@"<>()+,.]/g, "");
        },
        getGotoUrl() {
            let gotoUrlQuery = this.$route.query.goto;

            // determine if there is a goto with a hashtag
            let hashValue = window.location.hash.substr(1);
            if (hashValue) {
                gotoUrlQuery += String('#' + hashValue);
            }

            return gotoUrlQuery;
        },

        /**
         * redirect user to initial page
         * @param {url} mountedUrl can be null
         * @param {gotoUrl} gotoUrl can be null
         * @property {function} redirect user to login page
         * @return {void} redirect the user to login page
         */
        redirectBackToLogin(urlOnMount, gotoUrl) {
            const redirect = {
                query: {}
            }
            const urlStr = '/account/business'

            if (!urlOnMount) {
                redirect.path = '/login/unifiedlogin'
            } else {
                if (urlOnMount.includes(`${urlStr}/login/unifiedlogin`)) {
                    redirect.path = '/login/unifiedlogin'
                } else if (urlOnMount.includes(`${urlStr}/login/mobile`)) {
                    redirect.name = 'MobileLoginComponent'
                } else if (urlOnMount.includes(`${urlStr}/login/pwdlesslogin`)) {
                    redirect.name = 'PwdLessLoginRootComponent'
                } else if (urlOnMount.includes(`${urlStr}/ilogin`)) {
                    redirect.name = 'iLoginComponent'
                } else if (urlOnMount.includes(`${urlStr}/login`)) {
                    redirect.name = 'SimpleLoginRoot'
                } else {
                    redirect.path = '/login/unifiedlogin'
                }
            }
            redirect.query.goto = gotoUrl ? gotoUrl : undefined;
            this.$router.push(redirect);
        }

    }
}
